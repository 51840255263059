import React, { useCallback, useMemo, useRef, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"

export const ProgressBarContext = React.createContext<ProgressBarStore>({} as ProgressBarStore)

export function ProgressBarContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [progressMap, setProgressMap] = useState<Record<string, number>>({})
  const timerRef = useRef<Record<string, NodeJS.Timer>>({})

  const findProgressByRseeDocumentId = useCallback(
    (rseeDocumentId: string): number | undefined => progressMap[rseeDocumentId],
    [progressMap]
  )

  const stopTimer = useCallback(
    (rseeDocumentId: string): void => {
      if (timerRef.current[rseeDocumentId]) {
        clearInterval(timerRef.current[rseeDocumentId])
        delete timerRef.current[rseeDocumentId]
      }
    },
    [timerRef]
  )

  const startOrUpdateTimer = useCallback(
    (rseeDocumentId: string, isSlowPhase: boolean, interval = 1000) => {
      if (timerRef.current[rseeDocumentId]) {
        clearInterval(timerRef.current[rseeDocumentId])
      }

      timerRef.current[rseeDocumentId] = setInterval(() => {
        setProgressMap((prev) => {
          const currentProgress = prev[rseeDocumentId] ?? 0

          if (currentProgress >= 95) {
            stopTimer(rseeDocumentId)
          }

          let newProgress = currentProgress
          if (currentProgress < 30) {
            newProgress = Math.min(currentProgress + 1, 30)
          } else if (currentProgress >= 30 && !isSlowPhase) {
            startOrUpdateTimer(rseeDocumentId, true, 1385)
            return prev
          } else if (currentProgress >= 30) {
            newProgress = Math.min(currentProgress + 1, 95)
          }

          return {
            ...prev,
            [rseeDocumentId]: newProgress,
          }
        })
      }, interval)
    },
    [stopTimer]
  )

  const deleteProgress = useCallback(
    (rseeDocumentId: string) => {
      setProgressMap((prev) => {
        stopTimer(rseeDocumentId)
        const newProgressMap = { ...prev }
        delete newProgressMap[rseeDocumentId]

        return newProgressMap
      })
    },
    [stopTimer]
  )

  const resetProgressBar = useCallback(
    (rseeDocumentId: string) => {
      deleteProgress(rseeDocumentId)
      startOrUpdateTimer(rseeDocumentId, true)
    },
    [deleteProgress, startOrUpdateTimer]
  )

  const progressBarStore = useMemo(
    () => ({
      progressMap,
      timerRef,
      findProgressByRseeDocumentId,
      stopTimer,
      startOrUpdateTimer,
      deleteProgress,
      resetProgressBar,
    }),
    [progressMap, timerRef, findProgressByRseeDocumentId, stopTimer, startOrUpdateTimer, deleteProgress, resetProgressBar]
  )

  return <ProgressBarContext.Provider value={progressBarStore}>{children}</ProgressBarContext.Provider>
}

export interface ProgressBarStore {
  progressMap: Record<string, number>
  timerRef: React.MutableRefObject<Record<string, NodeJS.Timer>>
  findProgressByRseeDocumentId(rseeDocumentId: string | undefined): number | undefined
  stopTimer(rseeDocumentId: string): void
  startOrUpdateTimer(rseeDocumentId: string, isSlowPhase: boolean, interval?: number): void
  deleteProgress(rseeDocumentId: string): void
  resetProgressBar(rseeDocumentId: string): void
}
