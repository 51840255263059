import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { FicheConfiguree } from "../../../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"
import { stringToNumber } from "../../../../../core/services/helper-service"
import { UnitService } from "../../../../../core/services/unit-service"
import { SelectionContext } from "../../../../../pages/beem-shot/BSVariantDetailsPage/BSCustomizationTab/components/context/SelectionContext"
import CancelButton from "../../../../buttons/CancelButton/CancelButton"
import { ValidateButton } from "../../../../buttons/ValidateButton/ValidateButton"
import { DefaultTypography } from "../../../../typography/default-typography"
import { TextInputTypography } from "../../../../typography/textInputTypography"
import { NumberInput } from "../../../number-input/NumberInput"

interface IProps {
  openWarningFunctionnalUnit: boolean
  selectedRow: IniesRecord | MaterialRecord | FicheConfiguree
  actualQuantity: number
  isSubmitting: boolean

  onSubmit(newQuantity: number): void

  handleClose(): void
}

export function WarningFunctionalUnitDialog({
  openWarningFunctionnalUnit,
  onSubmit,
  isSubmitting,
  actualQuantity,
  selectedRow,
  handleClose,
}: Readonly<IProps>): React.JSX.Element {
  const [newQuantity, setNewQuantity] = useState<number>(actualQuantity)

  const { selectedFicheValue, selectedBSMaterialResult } = useContext(SelectionContext)

  useEffect(() => {
    if (actualQuantity !== newQuantity) {
      setNewQuantity(actualQuantity)
    }
  }, [])

  return (
    <Dialog maxWidth="md" fullWidth open={openWarningFunctionnalUnit} onClose={handleClose}>
      <DialogTitle>Êtes-vous sûr de vouloir affecter cette fiche ?</DialogTitle>
      <DialogContent color="black" sx={{ px: 2 }}>
        <Grid display="flex" flexDirection="row" container rowGap={1}>
          <Grid item xs={12}>
            <Typography variant="body2" pb={1}>
              Vous essayez d'affecter une fiche avec une unité différente. Veuillez modifier la quantité actuel si nécéssaire
              .
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" flexDirection="column">
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: 600,
                lineHeight: "26.5px",
                textAlign: "left",
              }}>
              Unité:
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            display="flex"
            flexDirection="column"
            sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
            <TextInputTypography label="Unité actuelle" />
            <DefaultTypography
              label={UnitService().unitEnumToLabel(selectedFicheValue?.unit) ?? ""}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
            />
          </Grid>

          <Grid
            item
            xs={5}
            display="flex"
            flexDirection="column"
            sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
            <TextInputTypography label="Unité de la fiche" />
            <DefaultTypography
              label={UnitService().unitEnumToLabel(selectedRow?.functionalUnit) ?? ""}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
            />
          </Grid>

          {selectedBSMaterialResult?.extractedQuantities && (
            <Grid display="flex" flexDirection="row" container rowGap={1}>
              <Grid item xs={2} display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: 600,
                    lineHeight: "26.5px",
                    textAlign: "left",
                  }}>
                  Quantité extraites:
                </Typography>
              </Grid>

              <Grid display="flex" container item xs={10} rowGap={1}>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Hauteur (m)" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.height?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Nombre" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.number?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Longueur (m)" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.length?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Surface (m²)" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.surface?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Grid display="flex" flexDirection="row" container>
          <Grid item xs={2} display="flex" flexDirection="column">
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: 600,
                lineHeight: "26.5px",
                textAlign: "left",
              }}>
              Modifier la quantité :
            </Typography>
          </Grid>

          <Grid item xs={10} display="flex" flexDirection="column">
            <NumberInput
              id="Quantité"
              label="Quantité actuelle"
              value={newQuantity}
              fullWidth={false}
              decimalScale={1}
              variant="outlined"
              handleChange={(value) => {
                setNewQuantity(stringToNumber(value))
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} label="Annuler" />
        <ValidateButton
          onClick={() => {
            onSubmit(newQuantity)
            handleClose()
          }}
          label="Confirmer"
          isSubmitting={isSubmitting}
        />
      </DialogActions>
    </Dialog>
  )
}
