import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { AppSideBarContext } from "../../core/context/side-bar/side-bar-context"
import { AppNavbarPage } from "../app-navbar V2/app-navbar-page"
import { AppSideBar } from "../app-side-navbar/app-side-navbar"

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: 96,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 300,
      letterSpacing: "-1.5px",
      lineHeight: "116.70000553131104%",
    },
    h2: {
      fontSize: 60,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 300,
      letterSpacing: "-0.5px",
      lineHeight: "120.00000476837158%",
    },
    h3: {
      fontSize: 48,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "116.70000553131104%",
    },
    h4: {
      fontSize: 34,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.25px",
      lineHeight: "123.50000143051147%",
    },
    h5: {
      fontSize: 24,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "133.39999914169312%",
    },
    h6: {
      fontSize: 20,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "160.0000023841858%",
    },
    body1: {
      fontSize: 16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "150%",
    },
    body2: {
      fontSize: 14,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0.17000000178813934px",
      lineHeight: "142.99999475479126%",
    },
    subtitle1: {
      fontSize: 16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.15000000596046448px",
      lineHeight: "auto",
    },
    subtitle2: {
      fontSize: 14,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0.10000000149011612px",
      lineHeight: "157.00000524520874%",
    },
    caption: {
      fontSize: 13,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0.4000000059604645px",
      lineHeight: "165.9999966621399%",
    },
    overline: {
      fontSize: 12,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "1px",
      lineHeight: "266.00000858306885%",
    },
    allVariants: {
      fontFamily: "Poppins, sans-serif",
    },
  },
  palette: {
    common: { black: "070F27", white: "#fff" },
    action: {
      active: "#070F27",
      hover: "#0BE2A0",
      hoverOpacity: 0.4,
      selected: "#0BE2A0",
      focus: "#0BE2A0",
      focusOpacity: 0.008,
    },
    text: {
      primary: "#070F27",
      secondary: "#8F8F8F",
      disabled: "#070F27",
    },
    primary: {
      main: "#0BE2A0",
      dark: "#08CA8F",
      light: "#55FFC6",
      contrastText: "#070F27",
    },
    secondary: {
      main: "#070F27",
      dark: "#374771",
      light: "#4965AC",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#40548D",
      dark: "#1B5E20",
      light: "#5376BD",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2E7D32",
      dark: "#070F27",
      light: "#4CAF50",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF2E2E",
      dark: "#CD0808",
      light: "#FF9A9A",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#EF6C00",
      dark: "#E65100",
      light: "#FF9800",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#F5F8F8",
      paper: "#ffffff",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#F5F8F8",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Poppins, sans-serif",
          backgroundColor: "#5376BD",
          color: "#ffffff",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "14px",
          padding: "6px 8px",
          gap: "0px",
          borderRadius: 5,
          opacity: 1,
        },
        arrow: {
          color: "#5376BD",
        },
      },
    },
    // @ts-ignore: special MUI object
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        *::-webkit-scrollbar {
          width: 8px;
        }

        *::-webkit-scrollbar-track {
          margin-top: 10px; 
          margin-bottom: 10px; 
        }
       
        *::-webkit-scrollbar-thumb {
          background-color: #d7d7d7;
          border-radius: 10px;
        }

        *::-webkit-scrollbar-thumb:hover {
          background-color: #55FFC6;
        }
      `,
    },
  },
})

export function BSLayout(): React.JSX.Element {
  const { showAppSide, setDrawerWidth, openAppSide, fullScreen } = useContext(AppSideBarContext)

  useEffect(() => {
    if (!showAppSide) {
      setDrawerWidth(0)
    } else if (openAppSide) {
      setDrawerWidth(240)
    } else if (!openAppSide) {
      setDrawerWidth(spacingToNumber(theme.spacing(7)) + 1)
    }
  }, [openAppSide, setDrawerWidth, showAppSide])

  function spacingToNumber(spacingValue: string): number {
    return parseInt(spacingValue, 10)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <Box sx={{ display: "flex" }}>
          <AppSideBar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 2,
              height: fullScreen ? "100vh" : "90vh",
              position: "sticky",
              overflow: "auto",
            }}>
            <AppNavbarPage />
            <Box display="contents" flex={1} border={1}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
